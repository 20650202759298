<template>
  <v-chip
    :color="type == 'SMS'?'warning':type == 'Email'?'info':type == 'LINE'?'success':'primary'"
    :class="type=='SMS'?'v-chip-light-bg warning--text':type=='Email'?'v-chip-light-bg info--text':type=='LINE'?'v-chip-light-bg success--text':'v-chip-light-bg primary--text'"
    small
  >
    {{ type }}
  </v-chip>
</template>

<script>

export default {
  props: { type: String },
  setup() {
    return {

    }
  },
}
</script>
