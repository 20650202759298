<template>
  <div>
    <v-form
      ref="formSendMessage"
      @submit.prevent="sendMessage"
    >
      <v-radio-group
        v-model="groupSendSelected"
        row
        class="my-0 py-0"
      >
        <v-radio
          :label="$t('full_name_for_person')"
          value="1"
        ></v-radio>
        <v-radio
          :label="$t('group_customers')"
          value="2"
        ></v-radio>
      </v-radio-group>
      <v-autocomplete
        v-if="groupSendSelected == '1'"
        v-model.trim="customer_id_pri"
        :items="customerList"
        :label="$t('choose_a_checker')"
        :placeholder="$t('input_three_charector')"
        item-text="customer_fullname"
        outlined
        dense
        :rules="[required]"
        :filter="customFilter"
        :search-input.sync="searchTextCustomer"
        :no-data-text="statusText"
        :loading="searchCustomerLoading"
        auto-select-first
        item-value="customer_id_pri"
      >
        <template v-slot:item="{ item }">
          {{ item.customer_fullname }}
          ({{ item.customer_id }})
        </template>
        <template v-slot:selection="{ item }">
          {{ item.customer_fullname }}
          ({{ item.customer_id }})
        </template>
      </v-autocomplete>
      <v-autocomplete
        v-if="groupSendSelected == '2'"
        v-model.trim="customerGroupSelected"
        :items="customerGroupData"
        :label="$t('select_customer_group')"
        dense
        :rules="[required]"
        outlined
        item-text="customer_group_name"
        :no-data-text="$t('no_information')"
        item-value="customer_group_id"
      ></v-autocomplete>
      <v-text-field
        v-model="title"
        outlined
        class="mt-3"
        dense
        :label="$t('title_name')"
        :rules="[required]"
      ></v-text-field>
      <quill-editor
        v-model="detailMessage"
        :options="editorOption"
        class="mt-3 mb-5"
      >
      </quill-editor>

      <div align="right">
        <v-btn
          color="primary"
          class="me-2"
          :loading="isLoading"
          :disabled="isLoading"
          type="submit"
        >
          {{ $t("send_message") }}
        </v-btn>
        <v-btn
          outlined
          color="secondary"
          @click="$emit('close')"
        >
          {{ $t("cancel") }}
        </v-btn>
      </div>
    </v-form>
  </div>
</template>

<script>
import { mdiCloudUploadOutline } from '@mdi/js'
import { ref, watch, onMounted } from '@vue/composition-api'
import { quillEditor } from 'vue-quill-editor'
import monthForSendMessagePromotion from '@/@fake-db/data/monthForSendMessagePromotion.json'
import customerGroup from '@/api/customer/customerGroup'
import { i18n } from '@/plugins/i18n'
import customerSelection from '@/api/customerSelection'
import promotions from '@/api/promotions/promotions'
import store from '@/store'
import { required } from '@/@core/utils/validation'

export default {
  components: { quillEditor },
  setup(props, { emit }) {
    const editorOption = ref({
      placeholder: '',
    })
    const formSendMessage = ref(null)
    const detailMessage = ref()
    const groupSendSelected = ref('1')
    const groupSendData = ref([
      {
        value: '1',
        label: 'ชื่อ-นามสกุล(รายบุคคล)',
      },
      {
        value: '2',
        label: 'กลุ่มลูกค้า',
      },
      {
        value: '3',
        label: 'วันเกิด',
      },
    ])
    const typeSelected = ref(3)
    const dataType = ref([
      {
        value: 3,
        label: 'LINE Notify & App Clinic Pro',
      },
      {
        value: 1,
        label: 'LINE Notify',
      },
      {
        value: 2,
        label: 'App Clinic Pro',
      },
    ])
    const customerData = ref([])
    const customerGroupSelected = ref('')
    const customerGroupData = ref([])
    const monthSelected = ref('')
    const monthData = ref(monthForSendMessagePromotion.data)
    const blob_image = ref('')
    const img = ref('')
    const search = ref('')
    const selectImage = ref(null)

    const searchCustomerLoading = ref(false)
    const statusText = ref(i18n.t('input_three_charector'))
    const searchTextCustomer = ref('')
    const customerList = ref([])
    const customer_id_pri = ref('')
    const isLoading = ref(false)
    const title = ref('')

    onMounted(() => {
      getCustomerGroupList()
    })

    watch(searchTextCustomer, value => {
      if (value && value.length >= 3) {
        statusText.value = i18n.t('data_loading')
        searchCustomerLoading.value = true
        getCustomerList()
      } else {
        customerList.value = []
        statusText.value = i18n.t('input_three_charector')
      }
    })

    watch([groupSendSelected], newValue => {
      if (newValue == 1) {
        customerGroupSelected.value = ''
        monthSelected.value = ''
      } else if (newValue == 2) {
        customer_id_pri.value = ''
        monthSelected.value = ''
      } else {
        customerGroupSelected.value = ''
        customer_id_pri.value = ''
      }
    })

    watch([groupSendSelected], value => {
      if (value) {
        if (value.length >= 3) {
          getCustomerList()
        } else {
          customerList.value = []
        }
      } else {
        customerList.value = []
      }
    })

    const { customerGroupList } = customerGroup
    const { customerSelectionList } = customerSelection

    const { promotionsAddEmail } = promotions

    const getCustomerGroupList = () => {
      const body = {
        searchtext: '',
        lang: i18n.locale,
        active_page: 1,
        per_page: '0',
      }
      customerGroupList(body).then(res => {
        customerGroupData.value = res.data
      })
    }
    const getCustomerList = () => {
      customerSelectionList({ searchtext: searchTextCustomer.value }).then(res => {
        customerList.value = res
        searchCustomerLoading.value = false
        statusText.value = !res.length ? i18n.t('no_data') : i18n.t('input_three_charector')
      })
    }
    const showSelectImage = () => {
      selectImage.click()
    }
    const previewFiles = event => {
      img.value = event.target.files[event.target.files.length - 1]
      blob_image.value = URL.createObjectURL(
        event.target.files[event.target.files.length - 1],
      )
    }
    const onCancel = () => {
      emit('cancel', false)
    }
    const sendMessage = () => {
      const isFormValid = formSendMessage.value.validate()
      if (!isFormValid) return
      isLoading.value = true
      promotionsAddEmail({
        send_to_id: typeSelected.value,
        customer_id_pri: groupSendSelected.value == '1' ? customer_id_pri.value : '',
        customer_group_id: groupSendSelected.value == '2' ? customerGroupSelected.value : '',
        promotion_header: title.value,
        promotion_message: detailMessage.value,
        lang: i18n.locale,
      }).then(res => {
        store.commit('app/ALERT', {
          message: res.message,
          color: res.response ? 'success' : 'error',
        })
        emit('onAdd')
        isLoading.value = false
      })
    }
    const customFilter = (item, queryText) => {
      const textOne = item.customer_fullname.toLowerCase()
      const textThree = item.customer_id.toLowerCase()
      const textFour = item.customer_tel.toLowerCase()
      const searchText = queryText.toLowerCase()

      return (
        textOne.indexOf(searchText) > -1
        || textThree.indexOf(searchText) > -1
        || textFour.indexOf(searchText) > -1
      )
    }

    return {
      title,
      editorOption,
      isLoading,
      formSendMessage,
      searchTextCustomer,
      statusText,
      searchCustomerLoading,
      customerList,
      detailMessage,
      groupSendSelected,
      groupSendData,
      typeSelected,
      dataType,
      customer_id_pri,
      customerData,
      customerGroupSelected,
      customerGroupData,
      monthSelected,
      monthData,
      blob_image,
      img,
      search,
      required,
      showSelectImage,
      previewFiles,
      onCancel,
      sendMessage,
      customFilter,
      icons: {
        mdiCloudUploadOutline,
      },

    }
  },

}
</script>

<style lang="scss">

.ql-toolbar.ql-snow {
    border: 1px solid #ccc;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    box-sizing: border-box;
    font-family: 'Kanit', sans-serif;
    padding: 8px;
}

.ql-container {
  box-sizing: border-box;
  font-family: 'Kanit', sans-serif;
  font-size: 16px;
  height: 300px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  margin: 0px;
  position: relative;
}

</style>
