import promotions from '@/api/promotions/promotions'
import smsPackage from '@/api/systemSetting/smsPackage'
import footerDataTables from '@/components/basicComponents/footerDataTables.json'
import footerDataTablesEN from '@/components/basicComponents/footerDataTablesEN.json'
import { formatPrice } from '@/plugins/filters'
import { i18n } from '@/plugins/i18n'
import { onMounted, ref, watch } from '@vue/composition-api'

export default function usePromotionsList() {
  const moment = require('moment')
  const isShowDate = ref(false)
  const searchtext = ref('')
  const promotion_modify = ref(moment(new Date()).format('YYYY-MM-DD'))

  const dataTableList = ref([])
  const totalDataTableList = ref(0)
  const segmentId = ref(0)
  const totalPage = ref(0)
  const options = ref({})
  const loading = ref(false)
  const footer = ref(i18n.locale == 'en' ? footerDataTablesEN : footerDataTables)
  const columns = ref([
    {
      text: '#',
      align: 'start',
      sortable: false,
      value: 'promotion_id',
      width: 50,
    },
    {
      text: i18n.t('type'),
      value: 'promotion_message',
      align: 'center',
      width: 100,
    },
    {
      text: i18n.t('customer_code'),
      value: 'customer_id',
      width: 120,
    },
    {
      text: i18n.t('firstname_lastname'),
      value: 'customer_fullname',
      width: 200,
    },
    {
      text: i18n.t('email'),
      value: 'customer_email',
      width: 120,
    },
    { text: i18n.t('tel'), value: 'customer_tel', width: 120 },
    {
      text: i18n.t('group_customers'),
      value: 'customer_group_name',
      width: 120,
    },
    {
      text: i18n.t('status'),
      value: 'promotion_status',
      width: 100,
      align: 'center',
    },
    { text: i18n.t('date'), value: 'promotion_modify', width: 170 },
    { text: i18n.t('topic'), value: 'promotion_title', width: 100 },
    {
      text: i18n.t('option'),
      value: 'actions',
      sortable: false,
      align: 'center',
      width: 100,
    },
  ])

  const typeSelect = ref('')
  const sendTypeList = ref([
    {
      value: '1',
      th: 'เขียนข้อความโปรโมท - LINE Notify & App Clinic Pro',
      en: 'Write Promotion - LINE Notify & App Clinic Pro',
    },
    {
      value: '2',
      th: 'เขียนข้อความโปรโมท - Email',
      en: 'Write Promotion - Email',
    },
  ])
  const isAddPromotion = ref(false)
  const isShowDetail = ref(false)
  const dataDetail = ref({})
  const smsAmount = ref(0)

  const { promotionsList } = promotions
  const { smsPackageList } = smsPackage

  onMounted(() => {
    fetchDataTable()
  })
  smsPackageList().then(res => {
    smsAmount.value = res[0].shop_sms_all
    sendTypeList.value.push({
      value: '3',
      th: `เขียนข้อความโปรโมท - SMS (เครดิต SMS คงเหลือ ${formatPrice(res[0].shop_sms_all)} เครดิต)`,
      en: `Write Promotion - SMS (Credit SMS Balance ${formatPrice(res[0].shop_sms_all)} Credit)`,
    })
  })
  const fetchDataTable = () => {
    loading.value = true
    promotionsList({
      searchtext: searchtext.value,
      promotion_modify: promotion_modify.value,
      active_page: options.value.page,
      per_page: options.value.itemsPerPage == -1 ? '0' : options.value.itemsPerPage,
    }).then(res => {
      const { data, count, segment, count_of_page } = res
      totalPage.value = count_of_page
      dataTableList.value = data
      totalDataTableList.value = count
      segmentId.value = segment
      loading.value = false
    })
  }

  watch([searchtext, promotion_modify, options], (newvalue, oldvalue) => {
    if (newvalue[0] !== oldvalue[0] || newvalue[1] !== oldvalue[1]) {
      options.value.page = 1
    }
    fetchDataTable()
  })

  return {
    isShowDetail,
    dataDetail,
    typeSelect,
    sendTypeList,
    isAddPromotion,
    isShowDate,
    totalPage,
    searchtext,
    promotion_modify,

    dataTableList,
    totalDataTableList,
    segmentId,
    options,
    loading,
    footer,
    columns,
    smsAmount,
    fetchDataTable,
  }
}
