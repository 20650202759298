import refreshToken from '@/plugins/refresh-token'
import { api } from '../main'

const promotionsList = async body => {
  //await refreshToken()
  const response = await api
    .post({
      path: 'promotion/list',
      body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.log('get promotion list err : ', err)

      return {}
    })

  return response
}

const promotionsAdd = async body => {
  ////await refreshToken()
  const response = await api
    .post({
      path: 'promotionbox/app',
      body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.log('add promotion line err : ', err)

      return {}
    })

  return response
}
const promotionsAddEmail = async body => {
  ////await refreshToken()
  const response = await api
    .post({
      path: 'promotionbox/email',
      body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.log('add promotion email err : ', err)

      return {}
    })

  return response
}

const promotionsAddSms = async body => {
  ////await refreshToken()
  const response = await api
    .post({
      path: 'promotionbox/sms',
      body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.log('add promotion email err : ', err)

      return {}
    })

  return response
}

export default {
  promotionsList, promotionsAdd, promotionsAddEmail, promotionsAddSms,
}
