<template>
  <div>
    <v-card>
      <v-card-title class="px-2">
        {{ $t('Promotions list') }}
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          class="d-none d-md-block"
          @click="isAddPromotion = true"
        >
          <v-icon class="me-1">
            {{ icons.mdiPencilOutline }}
          </v-icon>   <span>{{ $t('write_a_promotional_message') }}</span>
        </v-btn>
        <v-btn
          color="primary"
          class="d-block d-md-none"
          fab
          outlined
          icon
          @click="isAddPromotion = true"
        >
          <v-icon>
            {{ icons.mdiPencilOutline }}
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-row class="px-2">
        <v-col
          cols="12"
          md="6"
          class="py-0"
          lg="4"
        >
          <v-text-field
            v-model="searchtext"
            :label="$t('search')"
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
          md="6"
          class="py-0"
          lg="4"
        >
          <v-dialog
            ref="datepicker"
            v-model.trim="isShowDate"
            :return-value.sync="promotion_modify"
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="promotion_modify"
                :label="$t('date')"
                readonly
                outlined
                dense
                v-bind="attrs"
                v-on="on"
              >
                <template v-slot:append>
                  <v-tooltip
                    color="#212121"
                    top
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        color="primary"
                        v-bind="attrs"
                        v-on="on"
                        @click="promotion_modify = ''"
                      >
                        {{ icons.mdiCalendarBlank }}
                      </v-icon>
                    </template>
                    <span>{{ $t("all_dates") }}</span>
                  </v-tooltip>
                </template>
              </v-text-field>
            </template>
            <v-date-picker
              v-model.trim="promotion_modify"
              :locale="$i18n.locale"
              scrollable
            >
              <v-spacer></v-spacer>

              <v-btn
                text
                color="primary"
                @click="$refs.datepicker.save(promotion_modify)"
              >
                {{ $t("confirm") }}
              </v-btn>
              <v-btn
                text
                color="secondary"
                @click="isShowDate = false"
              >
                {{ $t("cancel") }}
              </v-btn>
              <v-spacer></v-spacer>
            </v-date-picker>
          </v-dialog>
        </v-col>
      </v-row>
      <v-data-table
        :headers="columns"
        :items="dataTableList"
        :options.sync="options"
        :server-items-length="totalDataTableList"
        :footer-props="footer"
        :loading="loading"
        hide-default-footer
        disable-sort
        :loading-text="$t('data_loading')"
        :no-data-text="$t('no_information')"
      >
        <template v-slot:[`item.promotion_id`]="{ index }">
          {{ segmentId + index + 1 }}
        </template>
        <template v-slot:[`item.promotion_message`]="{ item }">
          <PromotionsType :type="item.promotion_message"></PromotionsType>
        </template>
        <template v-slot:[`item.promotion_status`]="{ item }">
          <PromotionsStatus :status="+item.promotion_status" />
        </template>
        <template v-slot:[`item.promotion_modify`]="{ item }">
          <ConversDateTime :datetime="item.promotion_modify" />
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
            icon
            color="primary"
            small
            @click="dataDetail = item; isShowDetail =true"
          >
            <v-icon>{{ icons.mdiInformationOutline }}</v-icon>
          </v-btn>
        </template>
      </v-data-table>
      <v-divider></v-divider>
      <Pagination
        :page-data="options.page"
        :page-count="totalPage"
        :segment-id="+segmentId "
        :count-list="dataTableList.length"
        :total="+totalDataTableList"
        @pageChanged="(page)=>{options.page = page ; fetchDataTable()}"
        @itemChanged="(items)=>{options.itemsPerPage = items;options.page=1 ; fetchDataTable()}"
      />
    </v-card>
    <v-dialog
      v-model="isAddPromotion"
      persistent
      max-width="500"
    >
      <v-card>
        <v-card-title>{{ $t('Send Promotion Messages') }}</v-card-title>
        <v-card-text>
          <v-select
            v-model.trim="typeSelect"
            :items="sendTypeList"
            :label="$t('choose_type_message')"
            :item-text="$i18n.locale"
            item-value="value"
            dense
            outlined
          ></v-select>
          <AddPromotionLineBox
            v-if="typeSelect==1"
            @onAdd="isAddPromotion = false;fetchDataTable();typeSelect=0"
            @close="isAddPromotion = false;typeSelect=0"
          />
          <AddPromotionEmailBox
            v-if="typeSelect==2"
            @onAdd="isAddPromotion = false;fetchDataTable();typeSelect=0"
            @close="isAddPromotion = false;typeSelect=0"
          />
          <AddPromotionsSmsBox
            v-if="typeSelect==3"
            :sms-amount="+smsAmount"
            @onAdd="isAddPromotion = false;fetchDataTable();typeSelect=0"
            @close="isAddPromotion = false;typeSelect=0"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="isShowDetail"
      max-width="500"
      persistent
    >
      <v-card>
        <v-card-title>{{ $t('promotion_message') }}</v-card-title>
        <v-card-text>
          <v-simple-table dense>
            <template v-slot:default>
              <tbody>
                <tr>
                  <td>
                    {{ $t("to") }} :
                  </td>
                  <td>
                    {{ dataDetail.customer_fullname }}
                  </td>
                </tr>
                <tr>
                  <td>
                    {{ $t("subject") }} :
                  </td>
                  <td>
                    {{ dataDetail.promotion_title }}
                  </td>
                </tr>
                <tr>
                  <td
                    style="height: 100px"
                  >
                    {{ $t("message") }} :
                  </td>
                  <td>
                    {{ dataDetail.promotion_title }}
                  </td>
                </tr>
                <tr>
                  <td>
                    {{ $t("sent_when") }} :
                  </td>
                  <td>
                    <ConversDateTime :datetime="dataDetail.promotion_modify" />
                  </td>
                </tr>
                <tr>
                  <td>
                    {{ $t("status") }} :
                  </td>
                  <td>
                    <span v-if="dataDetail.promotion_status == '2'">
                      <v-chip
                        label
                        small
                        color="error"
                        class="v-chip-light-bg error--text"
                      >
                        {{ $t("error") }}</v-chip>
                    </span>
                    <span v-if="dataDetail.promotion_status == '1'">
                      <v-chip
                        label
                        small
                        color="success"
                        class="v-chip-light-bg success--text"
                      >
                        {{ $t("normal") }}</v-chip>
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    {{ $t("image") }} :
                  </td>
                  <td>
                    <v-img
                      width="300px"
                      class="rounded-lg elevation-3"
                      :src="dataDetail.promotion_content"
                    ></v-img>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="secondary"
            outlined
            @click="isShowDetail = false"
          >
            {{ $t('cancel') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mdiPencilOutline, mdiCalendarBlank, mdiInformationOutline } from '@mdi/js'
import usePromotionsList from './usePromotionsList'
import PromotionsStatus from '@/components/basicComponents/PromotionsStatus.vue'
import PromotionsType from '@/components/basicComponents/PromotionsType.vue'
import ConversDateTime from '@/components/basicComponents/ConversDateTime.vue'
import AddPromotionLineBox from './AddPromotionLineBox.vue'
import AddPromotionEmailBox from './AddPromotionEmailBox.vue'
import AddPromotionsSmsBox from './AddPromotionsSmsBox.vue'
import Pagination from '@/components/basicComponents/Pagination.vue'

export default {
  components: {
    PromotionsStatus,
    PromotionsType,
    ConversDateTime,
    Pagination,
    AddPromotionLineBox,
    AddPromotionEmailBox,
    AddPromotionsSmsBox,
  },
  setup() {
    return {
      ...usePromotionsList(),
      icons: {
        mdiPencilOutline, mdiCalendarBlank, mdiInformationOutline,
      },
    }
  },
}
</script>
